// Types
import { FormType } from '@/types/user';
import { LocalStorageData } from '@/types/localStorage';
import { PrimaryEntrantFormData } from '@/types/primaryEntrant';
import { CoEntrantFormData } from '@/types/coEntrant';
import { ChildFormData } from '@/types/child';
import { PCAJuniorFormData } from '@/types/pcaJunior';
import { GuestFormData } from '@/types/guest';

const localStorageKey = 'PCA_PARADE_CLIENT';

export function saveToLocalStorage(formType: FormType.PRIMARY_ENTRANT, value: PrimaryEntrantFormData): void;
export function saveToLocalStorage(formType: FormType.CO_ENTRANT, value: CoEntrantFormData): void;
export function saveToLocalStorage(formType: FormType.CHILD, value: ChildFormData): void;
export function saveToLocalStorage(formType: FormType.GUEST, value: GuestFormData): void;
export function saveToLocalStorage(formType: FormType, value: PrimaryEntrantFormData | CoEntrantFormData | ChildFormData | PCAJuniorFormData | GuestFormData): void {

  const serializedLocalStorageData: string | null = localStorage.getItem(localStorageKey);
  
  if (serializedLocalStorageData !== null) {
    const parsedLocalStorageData: LocalStorageData = JSON.parse(serializedLocalStorageData);
    localStorage.setItem(localStorageKey, JSON.stringify({ ...parsedLocalStorageData, ...{[formType]: value }} ));
  } else {
    localStorage.setItem(localStorageKey, JSON.stringify({ [formType]: value }));
  }
}

export function getFromLocalStorage(formType: FormType.PRIMARY_ENTRANT): PrimaryEntrantFormData;
export function getFromLocalStorage(formType: FormType.CO_ENTRANT): CoEntrantFormData;
export function getFromLocalStorage(formType: FormType.CHILD): ChildFormData;
export function getFromLocalStorage(formType: FormType.GUEST): GuestFormData;
export function getFromLocalStorage(formType: FormType): PrimaryEntrantFormData | CoEntrantFormData | ChildFormData | PCAJuniorFormData | GuestFormData | null {

  const serializedLocalStorageData: string | null = localStorage.getItem(localStorageKey);
  
  if (serializedLocalStorageData !== null) {
    const parsedLocalStorageData: LocalStorageData = JSON.parse(serializedLocalStorageData);
    const parsedLocalStorageDataAtKey: PrimaryEntrantFormData | CoEntrantFormData | ChildFormData | PCAJuniorFormData | GuestFormData | undefined = parsedLocalStorageData[formType];
    return parsedLocalStorageDataAtKey ?? null;

  } else {
    return null;
  }
};
// @ZACK - TODO: Create validations for all LocalStorage data retrieved to ensure it's not corrupted

export function clearLocalStorageByFormTypeKey(formType: FormType): void {
  
  const serializedLocalStorageData: string | null = localStorage.getItem(localStorageKey);
  
  if (serializedLocalStorageData !== null) {
    const parsedLocalStorageData: LocalStorageData = JSON.parse(serializedLocalStorageData);
    delete parsedLocalStorageData[formType];
    localStorage.setItem(localStorageKey, JSON.stringify(parsedLocalStorageData));
  }
}

export function clearAllLocalStorage(): void {
  for (const formType in Object.values(FormType)) {
    localStorage.removeItem(formType);
  }
}