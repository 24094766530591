import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-wrapper" }
const _hoisted_2 = {
  class: "secondary-font",
  style: {"font-size":"14px","font-weight":"600"}
}
const _hoisted_3 = {
  key: 0,
  class: "secondary-font",
  style: {"font-size":"14px","font-weight":"600"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.notification.show,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.notification.show) = $event)),
        color: _ctx.notification.color,
        timeout: _ctx.notification.timeout
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "mdi-close",
            variant: "text",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.notification.show = false))
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.notification.message), 1),
          (_ctx.notification.caption)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.notification.caption), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "color", "timeout"])
    ]),
    _createVNode(_component_Footer)
  ]))
}