// Types
import { Country, State } from '@/types/formOptions';

export type MemberDetails = {
  CITY: string;
  COUNTRY: Country;
  EMAIL: string;
  FIRSTNAME: string;
  INFORMALNAME: string;
  LASTNAME: string;
  MEMBERNUMBER: number;
  MIDDLENAME: string;
  PAIDTHRU: string;
  PHONE: string;
  REGIONID: number;
  REGIONCODE: string;
  REGIONNAME: string;
  STATE_PROVINCE: State;
  STATE_PROVINCE_ABBR: string;
  STREET1: string;
  STREET2: string | null;
  ZIP: string;
};

export type PrimaryEntrantDetails = {
  ADDRESS2: string;
  ADDRESS: string;
  AGE_RANGE_ID: number;
  CELL_PHONE: string;
  CITY: string;
  COST: number;
  COUNTRY: string;
  DAYTIME_PHONE: string | null;
  EMAIL: string;
  EMERGENCYCONTACTNAME: string;
  EMERGENCYCONTACTPHONE: string;
  ENTRANT_NUMBER: string | null;
  ENTRANT_TYPE_ID: number;
  ENTRY_DATE: string;
  FIRSTPARADE: number
  FIRST_NAME: string;
  GENDER_ID: 1 | 2 | 3 | null;
  ID: number;
  IS_ACTIVE: number;
  IS_PARENT: number;
  IS_VISIBLE: number;
  LAST_NAME: string;
  MEMBERNUMBER: number;
  MOBILITYDEVICE: number
  NAME_BADGE: string;
  NIGHT_PHONE: string | null;
  PARENT_ENTRANT_ID: number;
  PCA_TITLE: string;
  PHASE1PAID: 1 | null;
  REGION: number;
  RELATION_TO_ENTRANT: string | null;
  STATE: string;
  ZIP: string;
};

export type PCAJuniorDetails = {
  BIRTHMONTH: number;
  BIRTHYEAR: number;
  CITY: string;
  COUNTRY: string;
  COUNTRY_ABBR: string;
  FIRSTNAME: string;
  JUNIORREGIONLOC: string;
  LASTNAME: string;
  PARENTMEMBERNUMBER: number;
  PCAJUNIORID: number;
  RELATIONSHIPID: number;
  STATE: string;
  STATE_ABBR: string;
  STREET2: string | null;
  STREETADDRESS: string;
  ZIP: string;
};

// @ZACK - this should prob live in the forms.ts types file

export enum FormType {
  PRIMARY_ENTRANT = 'primaryEntrant',
  CO_ENTRANT = 'coEntrant',
  CHILD = 'child',
  GUEST = 'guest',
}

export type EntrantItem = {
  id: number;
  firstName: string;
  lastName: string;
  paid: boolean;
  price: number;
}

export type PaymentSummary = {
  [FormType.PRIMARY_ENTRANT]: EntrantItem[];
  [FormType.CO_ENTRANT]: EntrantItem[];
  [FormType.CHILD]: EntrantItem[];
  [FormType.GUEST]: EntrantItem[];
};

// { [FormType: string]: <total cost by quantity> }
export type TotalsByRegistrationFormType = {
  [FormType.PRIMARY_ENTRANT]: number,
  [FormType.CO_ENTRANT]: number,
  [FormType.CHILD]: number,
  [FormType.GUEST]: number,
};

// { [CompletionProgressFormType: string]: <has the user completed or intensionally skipped the form> }
export enum CompletionProgressFormType {
  PRIMARYENTRANT = 'PRIMARYENTRANT',
  CHILD = 'CHILD',
  COENTRANT = 'COENTRANT',
  GUEST = 'GUEST',
  CHECKOUT = 'CHECKOUT',
}
export type CompletionProgress = {
  [CompletionProgressFormType.CHECKOUT]: boolean,
  [CompletionProgressFormType.COENTRANT]: boolean,
  [CompletionProgressFormType.GUEST]: boolean,
  [CompletionProgressFormType.CHILD]: boolean,
  [CompletionProgressFormType.PRIMARYENTRANT]: boolean,
};