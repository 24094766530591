// Types
import { RouteRecordRaw } from 'vue-router';

// Local Types
export enum RoutePath {
  CATCH_ALL = '/:catchAll(.*)*',
  CHECKOUT = '/checkout',
  HOME = '/home',
  LOGIN = '/',
  PAYMENT_CONFIRMATION = '/payment-confirmation',
  REGISTER_CHILD = '/register-child/:id?',
  REGISTER_CO_ENTRANT = '/register-co-entrant/:id?',
  REGISTER_GUEST = '/register-guest/:id?',
  REGISTER_PRIMARY_ENTRANT = '/register-primary-entrant/:id?',
  // REGISTER_PCA_JUNIOR = '/register-pca-junior/:id?',
}

export enum RouteName {
  CATCH_ALL = '/catch-all',
  CHECKOUT = '/checkout',
  HOME = '/home',
  LOGIN = '/login',
  PAGE_NOT_FOUND = '/page-not-found',
  PAYMENT_CONFIRMATION = '/payment-confirmation',
  REGISTER_CHILD = '/register-child',
  REGISTER_CO_ENTRANT = '/register-co-entrant',
  REGISTER_GUEST = '/register-guest',
  REGISTER_PRIMARY_ENTRANT = '/register-primary-entrant',
  // REGISTER_PCA_JUNIOR = '/register-pca-junior',
}

const routes: RouteRecordRaw[] = [
  {
    children: [],
    component: () => import('@/pages/HomePage.vue'),
    name: RouteName.HOME,
    path: RoutePath.HOME,
    meta: { requiresAuth: true }
  },
  {
    children: [],
    component: () => import('@/pages/Login.vue'),
    name: RouteName.LOGIN,
    path: RoutePath.LOGIN,
    meta: { requiresGuest: true }
  },
  {
    children: [],
    component: () => import('@/pages/RegisterPrimaryEntrant.vue'),
    name: RouteName.REGISTER_PRIMARY_ENTRANT,
    meta: { requiresAuth: true },
    path: RoutePath.REGISTER_PRIMARY_ENTRANT,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterCoEntrant.vue'),
    name: RouteName.REGISTER_CO_ENTRANT,
    meta: { requiresAuth: true },
    path: RoutePath.REGISTER_CO_ENTRANT,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterGuest.vue'),
    name: RouteName.REGISTER_GUEST,
    meta: { requiresAuth: true },
    path: RoutePath.REGISTER_GUEST,
  },
  // {
  //   children: [],
  //   component: () => import('@/pages/RegisterPCAJunior.vue'),
  //   meta: { requiresAuth: true },
  //   path: RoutePath.REGISTER_PCA_JUNIOR,
  // },
  {
    children: [],
    component: () => import('@/pages/RegisterChild.vue'),
    name: RouteName.REGISTER_CHILD,
    meta: { requiresAuth: true },
    path: RoutePath.REGISTER_CHILD,
  },
  {
    children: [],
    component: () => import('@/pages/Checkout.vue'),
    name: RouteName.CHECKOUT,
    meta: { requiresAuth: true },
    path: RoutePath.CHECKOUT,
  },
  {
    children: [],
    component: () => import('@/pages/PaymentConfirmation.vue'),
    name: RouteName.PAYMENT_CONFIRMATION,
    meta: { requiresAuth: true },
    path: RoutePath.PAYMENT_CONFIRMATION,
  },
  {
    component: () => import('@/pages/PageNotFound.vue'),
    name: RouteName.PAGE_NOT_FOUND,
    meta: { requiresAuth: true },
    path: RoutePath.CATCH_ALL,
  },
];

export default routes;
