// Stores
import { useAppDataStore } from '@/stores/appDataStore';
import { useUserDataStore } from '@/stores/userDataStore';

// Types
import { MemberDetails } from '@/types/user';
import { RegionsResponse } from '@/types/apiResponses';

// Utils
import {
  fetchAllRegisteredEntrants,
  fetchCompletionProgress,
  fetchEntrantTypes,
  fetchEventDetails,
  fetchGenders,
  fetchMemberDetails,
  fetchPaymentSummary,
  fetchPCAAgeRanges,
  fetchPrimaryEntrantDetails,
  fetchRegions,
} from '@/api/apiCalls';

export const fetchInitData = async (): Promise<void> => {
  const userDataStore = useUserDataStore();
  const appDataStore = useAppDataStore();

  await Promise.all([
    fetchMemberDetails(),
    fetchPrimaryEntrantDetails(),
    fetchAllRegisteredEntrants(),
    fetchCompletionProgress(),
    fetchPaymentSummary(),
    fetchEventDetails(),
    fetchRegions(),
    fetchEntrantTypes(),
    fetchPCAAgeRanges(),
    fetchGenders(),
  ])
    .then(
      ([
        memberDetailsResponse,
        primaryEntrantDetailsResponse,
        allRegisteredEntrantsResponse,
        completionProgressResponse,
        paymentSummaryResponse,
        eventDetailsResponse,
        regionsResponse,
        entrantTypesResponse,
        pcaAgeRangesResponse,
        gendersResponse,
      ]) => {
        // Using the REGIONCODE we're provided for the MemberDetails, look up the corresponding REGIONID
        // (we'll need it for form submissions later)
        const regionAPIResponseItem: RegionsResponse[number] | undefined =
          regionsResponse.find(
            (region: RegionsResponse[number]) =>
              region.REGIONCODE === memberDetailsResponse.REGIONCODE
          );
        if (regionAPIResponseItem === undefined) {
          throw new Error(
            `initData.ts: could not find a RegionsResponse item with REGIONCODE ${memberDetailsResponse.REGIONCODE}`
          );
        }
        appDataStore.setOptionsData({
          regions: regionsResponse,
          entrantTypes: entrantTypesResponse,
          eventDetails: eventDetailsResponse,
          pcaAgeRanges: pcaAgeRangesResponse,
          genders: gendersResponse,
        });
        userDataStore.setMemberDetailsData({
          ...memberDetailsResponse,
          REGIONID: regionAPIResponseItem.REGIONID,
        } as MemberDetails);
        userDataStore.setPrimaryEntrantDetailsData(primaryEntrantDetailsResponse);
        userDataStore.setAllRegisteredEntrants(allRegisteredEntrantsResponse);
        userDataStore.setPaymentSummaryData(paymentSummaryResponse);
        if (completionProgressResponse !== null) {
          // The completion progress endpoint returns null if there is 0% completion
          // If that's the case, we don't need to do anything, just leave the defaults as-is
          userDataStore.setCompletionProgress(completionProgressResponse);
        }
      }
    )
    .catch((error) => {
      throw error;
    });
};
