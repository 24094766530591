// Stores
import { useAppDataStore } from './appDataStore';

// Types
import {
  CompletionProgress,
  CompletionProgressFormType,
  FormType,
  MemberDetails,
  PaymentSummary,
  PrimaryEntrantDetails,
} from '@/types/user';
import {
  AllRegisteredEntrantsResponse,
  CompletionProgressResponse,
  PaymentSummaryResponse,
  PaymentSummaryResponseItem,
  PrimaryEntrantDetailsResponse,
} from '@/types/apiResponses';

// Utils
import { clearAllLocalStorage } from '@/utils/localStorage';
import { defineStore } from 'pinia';
import { formatPrice } from '@/utils/dollaDollaBillzYall';
import {
  fetchAllRegisteredEntrants,
  fetchCompletionProgress,
  fetchPaymentSummary,
  fetchPrimaryEntrantDetails,
} from '@/api/apiCalls';
import { getCookie, setCookie, removeCookie } from '@/utils/cookieMonster';

// Local types
type UserDataStoreState = {
  allRegisteredEntrants: AllRegisteredEntrantsResponse;
  memberDetails: MemberDetails;
  primaryEntrantDetails: PrimaryEntrantDetails | null;
  completionProgress: CompletionProgress;
  paymentSummary: PaymentSummary;
  authToken: string | null;
};

export const authTokenName = 'authToken';

export const emptyPaymentSummary: PaymentSummary = {
  [FormType.PRIMARY_ENTRANT]: [],
  [FormType.CO_ENTRANT]: [],
  [FormType.CHILD]: [],
  [FormType.GUEST]: [],
};

export const emptyCompletionProgress: CompletionProgress = {
  [CompletionProgressFormType.CHECKOUT]: false,
  [CompletionProgressFormType.COENTRANT]: false,
  [CompletionProgressFormType.GUEST]: false,
  [CompletionProgressFormType.PRIMARYENTRANT]: false,
  [CompletionProgressFormType.CHILD]: false,
};

export const useUserDataStore = defineStore('userDataStore', {
  state: (): UserDataStoreState => ({
    allRegisteredEntrants: {} as AllRegisteredEntrantsResponse,
    memberDetails: {} as MemberDetails,
    primaryEntrantDetails: null,
    completionProgress: emptyCompletionProgress,
    paymentSummary: emptyPaymentSummary,
    authToken: getCookie(authTokenName),
  }),

  getters: {
    /**
     * @name getAllRegisteredEntrants
     * @description Returns the allRegisteredEntrants
     */
    getAllRegisteredEntrants(): UserDataStoreState['allRegisteredEntrants'] {
      return this.allRegisteredEntrants;
    },

    /**
     * @name getPaymentSummary
     * @description Returns the paymentSummary
     */
    getPaymentSummary(): UserDataStoreState['paymentSummary'] {
      return this.paymentSummary;
    },

    /**
     * @name getAuthToken
     * @description Returns the authToken    
     */
    getAuthToken(): UserDataStoreState['authToken'] {
      return this.authToken;
    },

    /**
     * @name getMemberDetails
     * @description Returns the memberDetails
     */
    getMemberDetails(): UserDataStoreState['memberDetails'] {
      return this.memberDetails;
    },

    /**
     * @name getMemberNumber
     * @description Returns the PCA member number
     */
    getMemberNumber(): UserDataStoreState['memberDetails']['MEMBERNUMBER'] {
      return this.memberDetails.MEMBERNUMBER;
    },

    /**
     * @name getPrimaryEntrantDetails
     * @description Returns the primaryEntrantDetails
     */
    getPrimaryEntrantDetails(): UserDataStoreState['primaryEntrantDetails'] {
      return this.primaryEntrantDetails;
    },

    /**
     * @name getFormattedBalanceDue
     * @description Returns the balance due for unpaid entrants as a formatted string $XX.XX
     */
    getFormattedBalanceDue(): string {
      return formatPrice(this.getBalanceDue);
    },

    /**
     * @name getBalanceDue
     * @description Computes the balance due for unpaid entrants as number
     */
    getBalanceDue(): number {
      let balanceDue: number = 0;
      for (const formType in this.paymentSummary) {
        for (const entrantItem of this.paymentSummary[formType as keyof PaymentSummary]) {
          if (!entrantItem.paid) {
            balanceDue += entrantItem.price;
          }
        }
      }
      return balanceDue;
    },

    /**
     * @name getTotalPaid
     * @description Computes the total paid to date
     */
    getTotalPaid(): string {
      let totalPaid: number = 0;
      for (const formType in this.paymentSummary) {
        for (const entrantItem of this.paymentSummary[formType as keyof PaymentSummary]) {
          if (entrantItem.paid) {
            totalPaid += entrantItem.price;
          }
        }
      }
      return formatPrice(totalPaid);
    },

    /**
     * @name getCompletionProgress
     * @description Returns the completionProgress
     */
    getCompletionProgress(): UserDataStoreState['completionProgress'] {
      return this.completionProgress;
    },

    /**
     * @name getCheckoutComplete
     * @description Returns true if the checkout form is complete (user has paid)
     */
    getCheckoutComplete(): boolean {
      return (
        this.getPrimaryEntrantDetails !== null && this.getPrimaryEntrantDetails.PHASE1PAID === 1
      );
    },

    /**
     * @name getEntrantNumber
     * @description Returns the entrant number if checkout is complete - else returns null
     */
    getEntrantNumber(): string | null {
      return this.getCheckoutComplete &&
        this.getPrimaryEntrantDetails !== null &&
        this.getPrimaryEntrantDetails.ENTRANT_NUMBER !== null
        ? this.getPrimaryEntrantDetails.ENTRANT_NUMBER
        : null;
    },

    /**
     * @name getCompletionProgressPercentage
     * @description Returns the percentage of forms completed
     */
    getCompletionProgressPercentage(): number {
      let percentage: number = 0;
      for (const isComplete of Object.values(this.completionProgress)) {
        if (isComplete) {
          percentage += 20;
        }
      }
      return percentage;
    },
  },

  actions: {
    login(token: string): void {
      setCookie(authTokenName, token);
      this.authToken = token;
    },

    logout(): void {
      removeCookie(authTokenName);
      this.authToken = null;
      this.memberDetails = {} as MemberDetails;
      this.primaryEntrantDetails = null;
      this.completionProgress = emptyCompletionProgress;
      this.paymentSummary = emptyPaymentSummary;
      clearAllLocalStorage();
    },

    setMemberDetailsData(data: UserDataStoreState['memberDetails']): void {
      this.memberDetails = data;
    },

    setPrimaryEntrantDetailsData(data: UserDataStoreState['primaryEntrantDetails']): void {
      this.primaryEntrantDetails = data;
    },

    setPaymentSummaryData(data: PaymentSummaryResponse): void {
      const appDataStore = useAppDataStore();
      const paymentSummary: PaymentSummary = {
        [FormType.PRIMARY_ENTRANT]: [],
        [FormType.CO_ENTRANT]: [],
        [FormType.CHILD]: [],
        [FormType.GUEST]: [],
      };
      for (const formType in data) {
        if (formType === 'PRIMARY_ENTRANT' && Object.keys(data[formType]).length > 0) {
          paymentSummary[FormType.PRIMARY_ENTRANT] = [
            {
              id: data[formType].ID,
              firstName: data[formType].FIRSTNAME,
              lastName: data[formType].LASTNAME,
              paid: data[formType].PAID === 1 ? true : false,
              price: appDataStore.getRegistrationPrices.base,
            },
          ];
        }
        if (formType === 'CO_ENTRANT' && Object.keys(data[formType]).length > 0) {
          paymentSummary[FormType.CO_ENTRANT] = [
            {
              id: data[formType].ID,
              firstName: data[formType].FIRSTNAME,
              lastName: data[formType].LASTNAME,
              paid: data[formType].PAID === 1 ? true : false,
              price: appDataStore.getRegistrationPrices.coEntrant,
            },
          ];
        }
        if (formType === 'CHILD') {
          paymentSummary[FormType.CHILD] = (
            data[formType as keyof PaymentSummaryResponse] as PaymentSummaryResponse['CHILD']
          ).map((item: PaymentSummaryResponseItem) => ({
            id: item.ID,
            firstName: item.FIRSTNAME,
            lastName: item.LASTNAME,
            paid: item.PAID === 1 ? true : false,
            price: appDataStore.getRegistrationPrices.cafp,
          }));
        }
        if (formType === 'GUEST') {
          paymentSummary[FormType.GUEST] = (
            data[formType as keyof PaymentSummaryResponse] as PaymentSummaryResponse['GUEST']
          ).map((item: PaymentSummaryResponseItem) => ({
            id: item.ID,
            firstName: item.FIRSTNAME,
            lastName: item.LASTNAME,
            paid: item.PAID === 1 ? true : false,
            price: appDataStore.getRegistrationPrices.adult,
          }));
        }
      }
      this.paymentSummary = paymentSummary;
    },

    setCompletionProgress(completionProgressResponse: CompletionProgressResponse): void {
      const completionProgress: CompletionProgress = {
        [CompletionProgressFormType.CHECKOUT]: false,
        [CompletionProgressFormType.COENTRANT]: false,
        [CompletionProgressFormType.GUEST]: false,
        [CompletionProgressFormType.PRIMARYENTRANT]: false,
        [CompletionProgressFormType.CHILD]: false,
      };
      for (const formType in completionProgressResponse) {
        if (formType === 'PRIMARYENTRANT') {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.PRIMARYENTRANT] === 0
              ? false
              : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing PRIMARYENTRANT value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.PRIMARYENTRANT] = isComplete;
        }
        if (formType === 'COENTRANT') {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.COENTRANT] === 0 ? false : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing COENTRANT value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.COENTRANT] = isComplete;
        }
        if (formType === 'PCA_JUNIOR_OR_CHILD') {
          const isComplete: boolean | undefined =
            completionProgressResponse['PCA_JUNIOR_OR_CHILD'] === 0
              ? false
              : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing PCA_JUNIOR_OR_CHILD value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.CHILD] = isComplete;
        }
        if (formType === CompletionProgressFormType.GUEST) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.GUEST] === 0 ? false : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing GUEST value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.GUEST] = isComplete;
        }
        if (formType === CompletionProgressFormType.CHECKOUT) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.CHECKOUT] === 0 ? false : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing CHECKOUT value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.CHECKOUT] = isComplete;
        }
      }
      this.completionProgress = completionProgress;
    },

    setAllRegisteredEntrants(data: AllRegisteredEntrantsResponse): void {
      this.allRegisteredEntrants = data;
    },

    async requestPrimaryEntrantDetails(): Promise<void> {
      const primaryEntrantDetailsResponse: PrimaryEntrantDetailsResponse | null =
        await fetchPrimaryEntrantDetails();
      this.setPrimaryEntrantDetailsData(primaryEntrantDetailsResponse);
    },

    async requestPaymentSummary(): Promise<void> {
      const paymentSummaryResponse: PaymentSummaryResponse = await fetchPaymentSummary();
      this.setPaymentSummaryData(paymentSummaryResponse);
    },

    async requestCompletionProgress(): Promise<void> {
      const completionProgressResponse: CompletionProgressResponse | null =
        await fetchCompletionProgress();
      if (completionProgressResponse !== null) {
        this.setCompletionProgress(completionProgressResponse);
      }
    },

    async requestAllRegisteredEntrants(): Promise<void> {
      const allRegisteredEntrantsResponse: AllRegisteredEntrantsResponse =
        await fetchAllRegisteredEntrants();
      this.setAllRegisteredEntrants(allRegisteredEntrantsResponse);
    },
  },
});
