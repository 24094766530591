/* eslint-disable */
// Ignore eslint rules for this file

// Stores
import { useUserDataStore } from '@/stores/userDataStore';

// Types
import { EntrantItem, FormType } from '@/types/user';
import { RoutePath, RouteName } from './routes';

// Utils
import { fetchInitData } from '@/utils/initData';
import routes from './routes';

// Vue
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalizedGeneric,
} from 'vue-router';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(
    to: RouteLocationNormalizedGeneric,
    from: RouteLocationNormalizedGeneric,
    savedPosition: any
  ) {
    // Always scroll to top
    return { top: 0 };

    // OR if you want to use saved position when using browser back/forward
    // return savedPosition || { top: 0 };
  },
});

// Navigation guard for authentication
router.beforeEach(
  async (
    to: RouteLocationNormalizedGeneric,
    from: RouteLocationNormalizedGeneric,
    next: NavigationGuardNext
  ) => {
    try {
      // Always allow LOGIN route
      if (to.path === RoutePath.LOGIN) {
        next();
        return;
      } else {
        const userDataStore = useUserDataStore();

        // Check if the route requires authentication
        const requiresAuth: boolean = to.matched.some((record) => record.meta.requiresAuth);
        const requiresGuest: boolean = to.matched.some((record) => record.meta.requiresGuest);

        const token: string | null = userDataStore.getAuthToken;

        // If route requires authentication and no token, redirect to login
        if (requiresAuth && !token) {
          next(RoutePath.LOGIN);
          return;
        }

        if (requiresAuth && token) {
          try {
            // Fetch init data if the user is authenticated
            await fetchInitData();
          } catch (error) {
            // Sometimes the fetchInitData call fails due to an unknown issue with the BE
            // If it fails once, try one more time
            try {
              await fetchInitData();
            } catch (_error) {
              throw _error;
            }
          }
        }

        // Don't allow CHECKOUT route if the user has no balance due
        const hasUnpaidEntrants: boolean = Object.values(userDataStore.getPaymentSummary)
          .flatMap((item: EntrantItem[]) => item)
          .some((item: EntrantItem) => !item.paid);
        if (to.path === RoutePath.CHECKOUT && !hasUnpaidEntrants) {
          next(RoutePath.HOME);
          return;
        }

        // Don't allow PAYMENT_CONFIRMATION route if the user hasn't at least paid for the Primary Entrant
        if (to.path === RoutePath.PAYMENT_CONFIRMATION && !userDataStore.getCheckoutComplete) {
          next(RoutePath.HOME);
          return;
        }

        // Block Primary Entrant page if the Primary Entrant has already been paid for
        if (
          to.path.includes(RouteName.REGISTER_PRIMARY_ENTRANT) &&
          userDataStore.getPaymentSummary[FormType.PRIMARY_ENTRANT][0]?.paid
        ) {
          next(RoutePath.HOME);
          return;
        }

        // Block Co-Entrant page if the Co-Entrant has already been paid for
        if (
          to.path.includes(RouteName.REGISTER_CO_ENTRANT) &&
          userDataStore.getPaymentSummary[FormType.CO_ENTRANT][0]?.paid
        ) {
          next(RoutePath.HOME);
          return;
        }

        // Otherwise, proceed with the navigation
        next();
      }
    } catch (error) {
      console.error(`An error occurred in router.beforeEach: ${error}`);
      window.alert(`An error occurred in router.beforeEach: ${error}`);
    }
  }
);

export default router;
