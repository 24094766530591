// Stores
import { useUserDataStore } from '@/stores/userDataStore';

// Types
import {
  AllRegisteredEntrantsResponse,
  AttendeeListResponse,
  ClearEntrantsResponse,
  CoEntrantOrGuestPCAMemberDetailsResponse,
  CompletionProgressResponse,
  DeleteUnpaidEntrantResponse,
  EntrantTypesResponse,
  EventDetailsResponse,
  GendersResponse,
  LoginUserResponse,
  MemberDetailsResponse,
  NationalRegionalTitlesResponse,
  PCAAgeRangesResponse,
  PCAJuniorDetailsResponse,
  PaymentSummaryResponse,
  PrimaryEntrantDetailsResponse,
  RegionsResponse,
  SaveChildResponse,
  SaveCoEntrantResponse,
  SaveCompletionProgressResponse,
  SaveGuestResponse,
  SavePCAJuniorResponse,
  SavePrimaryEntrantResponse,
  SubmitContactUsMessageResponse,
  SubmitPaymentResponse,
} from '@/types/apiResponses';
import {
  FetchDeleteUnpaidEntrantRequestBody,
  FetchLoginUserRequestBody,
  FetchSaveChildRequestBody,
  FetchSaveCoEntrantRequestBody,
  FetchSaveGuestRequestBody,
  FetchSavePCAJuniorRequestBody,
  FetchSavePrimaryEntrantRequestBody,
  FetchSubmitContactUsMessageRequestBody,
  FetchSubmitPaymentRequestBody,
} from '@/types/apiRequests';

// Utils
import axios from 'axios';

const handlerURL: string = 'https://dev.blinex.com/Parade/server/index.cfm?event=';

const ageRanges: string = 'main.getAgeRanges';
const clearEntrants: string = 'member.clearEntrant';
const coEntrantOrGuestPCAMemberDetails: string = 'member.getCoEntrantDetails';
const completionProgress: string = 'member.getProgress';
const deleteUnpaidEntrant: string = 'member.removeUnpaidEntrant';
const entrantTypes: string = 'main.getEntrantTypes';
const eventSettings: string = 'main.getEventSettings';
const nationalRegionalTitles: string = 'member.getTitles';
const attendeeList: string = 'member.attendeeList';
const genders: string = 'main.getGenders';
const juniorDetails: string = 'member.getJuniorsDetails';
const loginUser: string = 'member.login';
const memberDetails: string = 'member.getMemberDetails';
const paymentSummary: string = 'member.paymentSummary';
const primaryEntrantDetails: string = 'member.getEntrantDetails';
const regions: string = 'main.getRegions';
// const registeredEntrantsHeadCount: string = 'member.headCount';
const saveChild: string = 'member.saveChild';
const saveCoEntrant: string = 'member.coEntrantSave';
const saveCompletionProgress: string = 'member.updateProgress';
const saveGuest: string = 'member.guestSave';
const savePCAJunior: string = 'member.juniorSave';
const savePrimaryEntrant: string = 'member.primaryEntrantSave';
const submitContactUsMessage: string = 'member.submitContactUsMessage';
const submitPayment: string = 'member.entrantPayment';
const allRegisteredEntrants: string = 'member.getentireparty';

export const fetchAllRegisteredEntrants = async (): Promise<AllRegisteredEntrantsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchAllRegisteredEntrants: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${allRegisteredEntrants}`, {
    thekey: authToken,
  });
  console.log('allRegisteredEntrants response', response);
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid all registered entrants response from ${allRegisteredEntrants} endpoint`);
  }
};

export const fetchLoginUser = async (
  requestBody: FetchLoginUserRequestBody
): Promise<LoginUserResponse> => {
  const response = await axios.post(`${handlerURL}${loginUser}`, requestBody);
  if ('data' in response && 'status' in response.data && 'msg' in response.data) {
    return response.data;
  } else {
    throw new Error(`Invalid login user response from ${loginUser} endpoint`);
  }
};

export const fetchDeleteUnpaidEntrant = async (
  requestBody: FetchDeleteUnpaidEntrantRequestBody
): Promise<DeleteUnpaidEntrantResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${deleteUnpaidEntrant}`, {
    thekey: authToken,
    ...requestBody,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid delete unpaid entrant response from ${deleteUnpaidEntrant} endpoint`);
  }
};

export const fetchSubmitContactUsMessage = async (
  requestBody: FetchSubmitContactUsMessageRequestBody
): Promise<SubmitContactUsMessageResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${submitContactUsMessage}`, {
    thekey: authToken,
    ...requestBody,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(
      `Invalid submit contact us message response from ${submitContactUsMessage} endpoint`
    );
  }
};

export const fetchSubmitPayment = async (
  memberNumber: number,
  requestBody: FetchSubmitPaymentRequestBody
): Promise<SubmitPaymentResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${submitPayment}`, {
    thekey: authToken,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid submit payment response from ${submitPayment} endpoint`);
  }
};

export const fetchSaveCompletionProgress = async ( 
  formTypeToMarkComplete: 'PRIMARYENTRANT' | 'COENTRANT' | 'PCA_JUNIOR_OR_CHILD' | 'GUEST' | 'CHECKOUT'
): Promise<SaveCompletionProgressResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${saveCompletionProgress}`, {
    thekey: authToken,
    progressval: formTypeToMarkComplete,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid save completion progress response from ${saveCoEntrant} endpoint`);
  }
};

// This endpoint returns `null` if there is 0% completion - otherwise it returns the CompletionProgressResponse object
export const fetchCompletionProgress = async (): Promise<CompletionProgressResponse | null> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${completionProgress}`, {
    thekey: authToken,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else if (Array.isArray(response.data) && response.data.length === 0) {
    return null;
  } else {
    throw new Error(`Invalid completion progress response from ${completionProgress} endpoint`);
  }
};

export const fetchSavePrimaryEntrant = async (
  memberNumber: number,
  requestBody: FetchSavePrimaryEntrantRequestBody
): Promise<SavePrimaryEntrantResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${savePrimaryEntrant}`, {
    thekey: authToken,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    if ('msg' in response.data) {
      throw new Error(`Error saving primary entrant: ${response.data.msg}`);
    } else {
      throw new Error(`Invalid save primary entrant response from ${savePrimaryEntrant} endpoint`);
    }
  }
};

export const fetchSaveCoEntrant = async (
  requestBody: FetchSaveCoEntrantRequestBody
): Promise<SaveCoEntrantResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${saveCoEntrant}`, {
    thekey: authToken,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    if ('msg' in response.data) {
      throw new Error(`Error saving co-entrant: ${response.data.msg}`);
    } else {
      throw new Error(`Invalid save co-entrant response from ${saveCoEntrant} endpoint`);
    }
  }
};

export const fetchSaveGuest = async (
  requestBody: FetchSaveGuestRequestBody
): Promise<SaveGuestResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${saveGuest}`, {
    thekey: authToken,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save guest response from ${saveGuest} endpoint`);
  }
};

export const fetchSavePCAJunior = async (
  memberNumber: number,
  requestBody: FetchSavePCAJuniorRequestBody
): Promise<SavePCAJuniorResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${savePCAJunior}`, {
    thekey: authToken,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save PCA Junior response from ${saveCoEntrant} endpoint`);
  }
};

export const fetchSaveChild = async (
  requestBody: FetchSaveChildRequestBody
): Promise<SaveChildResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${saveChild}`, {
    thekey: authToken,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save child response from ${saveChild} endpoint`);
  }
};

export const fetchClearEntrants = async (): Promise<ClearEntrantsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${clearEntrants}`, {
    thekey: authToken,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid clear entrants response from ${clearEntrants} endpoint`);
  }
};

export const fetchMemberDetails = async (): Promise<MemberDetailsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${memberDetails}`, {
    thekey: authToken
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else {
    throw new Error(`Invalid member details response from ${memberDetails} endpoint`);
    // @ZACK - all these validations could be more robust if we checked for each expected field
  }
};

// @ZACK - let Josh now this endpoint is no longer needed
// export const fetchRegisteredEntrantsHeadcount = async (): Promise<RegisteredEntrantsHeadcountResponse> => {
//   const userDataStore = useUserDataStore();
//   const authToken: string | null = userDataStore.getAuthToken;
//   if (authToken === null) {
//     throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
//   }
//   const response = await axios.post(`${handlerURL}${registeredEntrantsHeadCount}`, {
//     thekey: authToken
//   });
//   if (Array.isArray(response.data)) {
//     return response.data;
//   } else {
//     throw new Error(
//       `Invalid registered entrants head count response from ${registeredEntrantsHeadCount} endpoint`
//     );
//   }
// };

export const fetchPrimaryEntrantDetails = async (): Promise<PrimaryEntrantDetailsResponse | null> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${primaryEntrantDetails}`, {
    thekey: authToken,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else if (Array.isArray(response.data) && response.data.length === 0) {
    return null;
  } else {
    throw new Error(
      `Invalid primary entrant details response from ${primaryEntrantDetails} endpoint`
    );
  }
};

export const fetchPaymentSummary = async (): Promise<PaymentSummaryResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${paymentSummary}`, {
    thekey: authToken,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid payment summary response from ${paymentSummary} endpoint`);
  }
};

export const fetchCoEntrantOrGuestPCAMemberDetails = async (
  coEntrantOrGuestPCAMemberNumber: number
): Promise<CoEntrantOrGuestPCAMemberDetailsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${coEntrantOrGuestPCAMemberDetails}`, {
    thekey: authToken,
    membernumber: coEntrantOrGuestPCAMemberNumber,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else {
    console.error(`Invalid co-entrant/guest PCA member details response from ${coEntrantOrGuestPCAMemberDetails} endpoint`)
    throw new Error(
      `Invalid member number`
    );
  }
};

export const fetchJuniorDetails = async (
  associatedMemberNumber: number
): Promise<PCAJuniorDetailsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${juniorDetails}`, {
    thekey: authToken,
    membernumber: associatedMemberNumber,
  });
  if (Array.isArray(response.data)) {
    return response.data;
  } else {
    throw new Error(`Invalid junior details response from ${juniorDetails} endpoint`);
  }
};

export const fetchEventDetails = async (): Promise<EventDetailsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${eventSettings}`, { thekey: authToken });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error(`Invalid event details response from ${eventSettings} endpoint`);
  }
};

export const fetchNationalRegionalTitles = async (memberNumber: number): Promise<NationalRegionalTitlesResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchNationalRegionalTitles: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${nationalRegionalTitles}`, { 
    thekey: authToken,
    memberNumber,
  });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data.map((item: { NAME: string }) => item.NAME);
  } else {
    throw new Error(`Invalid national regional titles response from ${nationalRegionalTitles} endpoint`);
  }
};

export const fetchRegions = async (): Promise<RegionsResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${regions}`, { thekey: authToken });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data.slice(1); // The item at index 0 is null for some reason - get 'er out of there!
  } else {
    throw new Error(`Invalid regions response from ${regions} endpoint`);
  }
};

export const fetchEntrantTypes = async (): Promise<EntrantTypesResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${entrantTypes}`, { thekey: authToken });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data as EntrantTypesResponse;
  } else {
    throw new Error(`Invalid entrant types response from ${entrantTypes} endpoint`);
  }
};

export const fetchPCAAgeRanges = async (): Promise<PCAAgeRangesResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${ageRanges}`, { thekey: authToken });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data as PCAAgeRangesResponse;
  } else {
    throw new Error(`Invalid PCA age ranges response from ${ageRanges} endpoint`);
  }
};

export const fetchGenders = async (): Promise<GendersResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) {
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${genders}`, { thekey: authToken });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data as GendersResponse;
  } else {
    throw new Error(`Invalid genders response from ${genders} endpoint`);
  }
};

export const fetchAttendeeList = async (): Promise<AttendeeListResponse> => {
  const userDataStore = useUserDataStore();
  const authToken: string | null = userDataStore.getAuthToken;
  if (authToken === null) { 
    throw new Error('fetchDeleteUnpaidEntrant: authToken is null');
  }
  const response = await axios.post(`${handlerURL}${attendeeList}`, {
    thekey: authToken,
  });
  if ('data' in response && Array.isArray(response.data)) {
    return response.data;
  } else {
    throw new Error(`Invalid attendee list response from ${attendeeList} endpoint`);
  }
};
